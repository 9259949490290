import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/** 
The `src/redux/slices/enterprise_user.js` file is a Redux slice that manages the state for enterprise users. It defines actions and reducers for loading, error handling, fetching, creating, editing, and deleting enterprise users. The state includes properties like `isLoading`, `error`, `enterprise_users`, `enterprise_user`, `sortBy`, and `filters`. The actions use `axios` to make HTTP requests to the `/api/enterprise_users` endpoint. The file exports functions that dispatch these actions, such as `getEnterpriseUsers`, `getEnterpriseUser`, `createEnterpriseUser`, `updateEnterpriseUser`, and `deleteEnterpriseUser`.
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/enterprise_users';

const initialState = {
  isLoading: false,
  error: null,
  enterprise_users: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  enterprise_user: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'enterprise_users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET enterprise_users
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_users = action.payload;
    },

    // GET EnterpriseUser
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_user = action.payload;
    },

    // Create EnterpriseUser
    createEnterpriseUserSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_manager = action.payload;
    },

    // edit EnterpriseUser
    editEnterpriseUserSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_manager = action.payload;
    },

    // delete EnterpriseUser
    deleteEnterpriseUser(state, id) {
      state.isLoading = false;
      state.enterprise_users = state.enterprise_users.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setEnterpriseUsers(state, action) {
      state.enterprise_users = action.payload;
    },

    //  SORT & FILTER EnterpriseUser
    sortByEnterpriseUser(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEnterpriseUsers(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getEnterpriseUser(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getEnterpriseUser response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createEnterpriseUser(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEnterpriseUser(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_user));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEnterpriseUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteEnterpriseUserSuccess(id));
      dispatch(slice.actions.deleteEnterpriseUsers(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
