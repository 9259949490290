import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { isValidToken, setSession } from '../../utils/jwt';
/** 
The file `src/redux/slices/enterprise.js` is a Redux slice for managing the state related to 'enterprises'. It uses Redux Toolkit's `createSlice` function to generate action creators and action types. The initial state includes properties such as `isLoading`, `error`, `enterprises`, `enterprise`, `sortBy`, and `filters`. The slice includes various reducers to handle actions like starting loading, handling errors, getting enterprises, editing, deleting, setting, and sorting enterprises.The file also exports several async functions for performing CRUD operations on enterprises. These functions dispatch actions to update the state and make HTTP requests to an API located at '/api/enterprises' using axios. The functions include `getEnterprises`, `get
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/enterprises';

const initialState = {
  isLoading: false,
  error: null,
  enterprises: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  enterprise: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice(
  {
  name: 'enterprises',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET enterprises
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.enterprises = action.payload;
    },

    // GET Enterprise
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.enterprise = action.payload;
    },

    // edit Enterprise
    editEnterpriseSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // delete Enterprise
    deleteEnterprise(state, id) {
      state.isLoading = false;
      state.enterprises = state.enterprises.filter((s) => s.id !== id);
    },

    // SET Enterprise
    setEnterprises(state, action) {
      state.enterprises = action.payload;
    },

    //  SORT & FILTER Enterprise
    sortByEnterprise(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEnterprises(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEnterprise(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getEnterprise response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createEnterprise(data, setEnterprises) {
  console.log("DATA CREATE", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprises));
      setEnterprises(response.data.enterprise);
      setSession(response.data.accessToken);
      window.location.reload();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeEnterprise(enterprise_token, setEnterprises) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/change_enterprise?enterprise_token=${enterprise_token}`);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise));
      setEnterprises(response.data.enterprise);
      setSession(response.data.accessToken);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEnterprise(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEnterprise(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteEnterpriseSuccess(id));
      dispatch(slice.actions.deleteEnterprises(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
