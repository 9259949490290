import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

/** 
The file `src/redux/store.js` is responsible for setting up the Redux store for the application. It imports necessary functionalities from Redux toolkit, Redux Persist, and the root reducer. The `configureStore` function is used to create the store with the persisted root reducer and custom middleware settings that disable serializable and immutable checks. The store is then persisted using `persistStore`. The `dispatch` function from the store, along with `useSelector` and `useDispatch` hooks from React-Redux, are re-exported for use in other parts of the application.
*/

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
