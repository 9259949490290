import PropTypes, { string } from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// Start Form
import EnterpriceForm from '../pages/enterprise_manager/EnterpriceForm';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import AdminHome from '../pages/enterprise_manager/Home';
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
/** 
The `AuthGuard.js` file is a part of an authentication guard system that controls the access and routing of users based on their roles and authentication status. It imports several hooks and components from different files. It uses the `useAuth` hook to check if the user is authenticated and their role (enterprise_manager, admin, teacher, student). The file contains a function `AuthGuard` which checks if the authentication process is initialized, if not, it displays a loading screen. If the user is not authenticated, it redirects them to the login page. If the user is authenticated, it checks the user's role and redirects them to the appropriate page. For example, if the user role is 'enterprise_manager' and the user's information is incomplete,
*/

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  type: string,
  children: PropTypes.node,
};

export default function AuthGuard({children }) {
  const { user, isAuthenticated, isInitialized } = useAuth();

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }
  console.log('auth')
  if (user && user.role === 'enterprise_manager' && pathname === '/dashboard') {
    if(user.incompleate){
      return <EnterpriceForm />;
    }
    return <Navigate to={'/admin/home'} />;
  }

  if (user && user.role === 'admin' && pathname === '/dashboard') {
    return <Navigate to={'/admin/home'} />;
  }
  
  if (user && user.is_teacher && pathname === '/dashboard') {
    console.log('teacher')  
    if(user.incompleate_teacher){
      return <Navigate to={'/teacher/start'} />;
    }
  }

  if (user && user.role === 'student' && pathname === '/dashboard') {
    return <Navigate to={'/student/home'} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
