import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/** 
The `src/redux/slices/quiz.js` file is a Redux slice that manages the state related to quizzes. It includes an initial state for quizzes and defines several actions and reducers to manipulate this state. The actions include starting loading, handling errors, getting all quizzes, getting a single quiz, creating a quiz, editing a quiz, deleting a quiz, and setting quizzes. It also includes actions for sorting and filtering quizzes. 

The file also exports several async functions that dispatch the defined actions and handle API calls to get, create, update, and delete quizzes using axios. These functions include error handling and dispatch actions to update the state accordingly.
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/quizzes';

const initialState = {
  isLoading: false,
  error: null,
  quizzes: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  quiz: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET quizzes
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.quizzes = action.payload;
    },

    // GET Quiz
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.quiz = action.payload;
    },

    // Create Quiz
    createQuizSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // edit Quiz
    editQuizSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // delete Quiz
    deleteQuiz(state, id) {
      state.isLoading = false;
      state.quizzes = state.quizzes.filter((s) => s.id !== id);
    },

    // SET Quiz
    setQuizzes(state, action) {
      state.quizzes = action.payload;
    },

    //  SORT & FILTER Quiz
    sortByQuiz(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getQuizzes(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getQuiz(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getQuiz response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createQuiz(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.quiz));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateQuiz(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.quiz));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteQuiz(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteQuizSuccess(id));
      dispatch(slice.actions.deleteQuizzes(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
