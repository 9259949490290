// ----------------------------------------------------------------------

import { Dashboard } from "@mui/icons-material";

/**
  Path configuration for various routes in the application, organized by role and functionality.
*/

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_TEACHER = '/teacher';
const ROOTS_STUDENT = '/student';
const ROOTS_ADMIN = '/admin';
const ROOTS_MANAGER = '/manager'
const ROOT_PROFILE = '/profile';


// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_STUDENT = {
  root: ROOTS_STUDENT,
  home: path(ROOTS_STUDENT, '/home'),
  quiz: (id) => path(ROOTS_STUDENT, `/quiz/${id}`),
  quizSection: (id) => path(ROOTS_STUDENT, `/quiz-section/${id}`),
  quizSummary: (id) => path(ROOTS_STUDENT, `/quiz-summary/${id}`),
  quizPreview: (id, retry) => path(ROOTS_STUDENT, `/quiz-preview/${id}?retry=${retry}`),
  group: (token) => path(ROOTS_STUDENT, `/group/${token}`),
};

export const PATH_TEACHER = {
  root: ROOTS_TEACHER,
  home: path(ROOTS_TEACHER, '/home'),
  sectionsExplore: path(ROOTS_TEACHER, `/sections`),
  facturationData: path(ROOTS_TEACHER, `/facturation-data`),
  quizEditor: path(ROOTS_TEACHER, '/quiz-editor'),
  materialList: path(ROOTS_TEACHER, '/material-list'),
  materialEditor: path(ROOTS_TEACHER, '/material-editor'),
  quizList: path(ROOTS_TEACHER, '/quiz-list'),
  questionBank: path(ROOTS_TEACHER, '/question-bank'),
  questionEditor: path(ROOTS_TEACHER, '/question-editor'),
  questionCreate: path(ROOTS_TEACHER, '/question-create'),
  videoContent: {
    root: path(ROOTS_TEACHER, '/video_content'),
    new: path(ROOTS_TEACHER, '/video_content/create'),
    view: (id) => path(ROOTS_TEACHER, `/video_content/${id}`),
    edit: (id) => path(ROOTS_TEACHER, `/video_content/${id}/edit`),
  },
  question: {
    root: path(ROOTS_TEACHER, '/question'),
    new: path(ROOTS_TEACHER, '/question/create'),
    setQuestionResponse: (id) => path(ROOTS_TEACHER, `/question/${id}/set-response`),
    view: (id) => path(ROOTS_TEACHER, `/question/${id}`),
    edit: (id) => path(ROOTS_TEACHER, `/question/${id}/edit`)
  },
  quiz: {
    root: path(ROOTS_TEACHER, '/quiz'),
    new: path(ROOTS_TEACHER, '/quiz/create'),
    view: (id) => path(ROOTS_TEACHER, `/quiz/${id}`),
    edit: (id) => path(ROOTS_TEACHER, `/quiz/${id}/edit`),
  },
}

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  home: path(ROOTS_ADMIN, '/home'),
  form: path(ROOTS_ADMIN, '/enterprice-form'),
  section: {
    root: path(ROOTS_ADMIN, '/sections'),
    new: path(ROOTS_ADMIN, '/sections/create'),
    view: (id) => path(ROOTS_ADMIN, `/sections/${id}`),
    edit: (id) => path(ROOTS_ADMIN, `/sections/${id}/edit`),
  }
}

export const PATH_MANAGER = {
  root: ROOTS_MANAGER,
  home: {
    root: path(ROOTS_MANAGER, '/home'),
    form: path(ROOTS_MANAGER, '/enterprice-form'),
  },
  group: {
    root: path(ROOTS_MANAGER, '/groups'),
    new: path(ROOTS_MANAGER, '/groups/create'),
    view: (id) => path(ROOTS_MANAGER, `/groups/${id}`),
    edit: (id) => path(ROOTS_MANAGER, `/groups/${id}/edit`),
  },
  areas: {
    root: path(ROOTS_MANAGER, '/areas'),
    new: path(ROOTS_MANAGER, '/areas/create'),
    view: (id) => path(ROOTS_MANAGER, `/areas/${id}`),
    edit: (id) => path(ROOTS_MANAGER, `/areas/${id}/edit`),
  },
  user_manager: {
    root: path(ROOTS_MANAGER, '/user-managers'),
    new: path(ROOTS_MANAGER, '/user-managers/create'),
    view: (id) => path(ROOTS_MANAGER, `/user-managers/${id}`),
    edit: (id) => path(ROOTS_MANAGER, `/user-managers/${id}/edit`),
  },
  users: {
    root: path(ROOTS_MANAGER, '/users'),
    new: path(ROOTS_MANAGER, '/users/create'),
    view: (id) => path(ROOTS_MANAGER, `/users/${id}`),
    edit: (id) => path(ROOTS_MANAGER, `/users/${id}/edit`),
  }
}

export const PATH_PROFILE = {
  root: ROOT_PROFILE,
  invitations: path(ROOT_PROFILE, '/own-invitations'),
  invitation: (token) => path(ROOT_PROFILE, `/invitation/${token}`),
}


export const PATH_PAGE = {

  searchUnit: '/search',
  searchCourse: '/search-course',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  pageUpload: {
    root: '/upload',
    view: (token) => path(`/upload/?token=${token}`),
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: {
    root: path(ROOTS_DASHBOARD, '/home')
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    home: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    profile_edit: path(ROOTS_DASHBOARD, '/user/profile/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  proyect: {
    root: path(ROOTS_DASHBOARD, '/proyect'),
    list: path(ROOTS_DASHBOARD, '/proyect/list'),
    new: path(ROOTS_DASHBOARD, '/proyect/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/proyect/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/proyect/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/proyect/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/proyect/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  subdomain: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`)
  },
  provider: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`)
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
