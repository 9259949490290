import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import EnterpriseGuard from '../guards/EnterpriseGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

/** 
 Main routing configuration for the React application, utilizing React Router and lazy-loaded components.
*/
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Student
    {
      path: 'student',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'home', element: <StudentHome /> },
        { path: 'area/:id', element: <StudentArea/> },
        { path: 'quiz/:id', element: <Quiz /> },
        { path: 'quiz-section/:id', element: <QuizSection /> },
        { path: 'quiz-preview/:id', element: <QuizPreview /> },
        { path: 'group/:token', element: <Group />}
      ],
    },
    // Student with no guard
    {
      path: 'study',
      element: (
        <DashboardLayout />
      ),
      children: [
        { path: 'group/:token', element: <Group />}
      ],
    },
    // Teacher
    {
      path: 'teacher',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'home', element: <TeacherHome />},
        { path: 'start', element: <TeacherStart />},
        { path: 'sections', element: <TeacherSectionsExplorer/> },
        { path: 'facturation-data', element: <TeacherFacturationData/> },
        { path: 'video_content', element: <VideoContentIndex /> },
        { path: 'video_content/create', element: <VideoContentCreate /> },
        { path: 'video_content/:id/edit', element: <VideoContentEdit /> },
        { path: 'video_content/:id', element: <VideoContentShow /> },
        { path: 'question', element: <QuestionIndex /> },
        { path: 'question/create', element: <QuestionCreate /> },
        { path: 'question/:id/edit', element: <QuestionEdit /> },
        { path: 'question/:id/set-response', element: <QuestionSetResponse />},
        { path: 'question/:id', element: <QuestionShow /> },
        { path: 'quiz', element: <QuizIndex /> },
        { path: 'quiz/create', element: <QuizCreate /> },
        { path: 'quiz/:id/edit', element: <QuizEdit /> },
        { path: 'quiz/:id', element: <QuizShow /> },
      ],
    },
    // Admin
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'home', element: <AdminHome/>},
        { path: 'sections', element: <SectionIndex /> },
        { path: 'sections/create', element: <SectionCreate /> },
        { path: 'sections/:id/edit', element: <SectionEdit /> },
        { path: 'sections/:id', element: <SectionShow /> },
        { path: 'groups', element: <GroupIndex /> },
        { path: 'groups/create', element: <GroupCreate /> },
        { path: 'groups/:id/edit', element: <GroupEdit /> },
        { path: 'groups/:id', element: <GroupShow /> }
      ],
    },
    // Manager
    {
      path: 'manager',
      element: (
        <AuthGuard>
          <EnterpriseGuard>
            <DashboardLayout />
          </EnterpriseGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'enterprice-form', element: <EnterpriceForm/>},
        { path: 'home', element: <ManagerHome/>},
        { path: 'groups', element: <GroupIndex /> },
        { path: 'groups/create', element: <GroupCreate /> },
        { path: 'groups/:id/edit', element: <GroupEdit /> },
        { path: 'groups/:id', element: <GroupShow /> },
        { path: 'areas', element: <AreaIndex /> },
        { path: 'areas/create', element: <AreaCreate /> },
        { path: 'areas/:id/edit', element: <AreaEdit /> },
        { path: 'areas/:id', element: <AreaShow /> },
        { path: 'user-managers', element: <UserManagerIndex /> },
        { path: 'user-managers/create', element: <UserManagerCreate /> },
        { path: 'user-managers/:id/edit', element: <UserManagerEdit /> },
        { path: 'user-managers/:id', element: <UserManagerShow /> },
        { path: 'users', element: <UserIndex /> },
        { path: 'users/create', element: <UserCreate /> },
        { path: 'users/:id/edit', element: <UserEdit /> },
        { path: 'users/:id', element: <UserShow /> }
      ],
    },
    // Common User
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'own-invitations', element: <OwnInvitations /> },
      ]
    },
    // Common User
    {
      path: 'profile',
      children: [
        { path: 'invitation/:token', element: <Invitation /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        {
          path: 'user',
          children: [
            { path: 'profile/edit', element: <UserProfileEdit /> },
          ],
        },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // Demo Routes
    {
      path: '*',
      element:(   
        <DashboardLayout />
      ),
      children: [
        { path: 'demo', element: <DemoVideo /> },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'course', element: <Course /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: 'upload', element: <PageUpload /> },
        { path: 'upload_resume', element: <PageUploadResume /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: '/student',
      element: <MainLayout/>,
      children: [
        { path: 'search', element: <Search /> },
        { path: 'search-course', element: <SearchCourse /> },
      ]
    },
    {
      path: '/student',
      element: <DashboardLayout/>,
      children: [
        { path: 'section-recomendation', element: <SectionRecomendation /> },
        { path: 'show-video-content', element: <ShowVideoContent /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserProfileEdit = Loadable(lazy(() => import('../pages/dashboard/UserProfileEdit')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const DemoVideo = Loadable(lazy(() => import('../pages/DemoVideo')));
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Search = Loadable(lazy(() => import('../pages/Search')));
const SearchCourse = Loadable(lazy(() => import('../pages/SearchCourse')));
const Course = Loadable(lazy(() => import('../pages/Course')));
const SectionRecomendation = Loadable(lazy(() => import('../pages/SectionRecomendation')));
const ShowVideoContent = Loadable(lazy(() => import('../pages/ShowVideoContent')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const PageUpload = Loadable(lazy(() => import('../pages/Upload')));
const PageUploadResume = Loadable(lazy(() => import('../pages/UploadResume')));

// ---- ADMIN ROUTES -----
// HOME
const AdminHome = Loadable(lazy(() => import('../pages/enterprise_manager/Home')));
const EnterpriceForm = Loadable(lazy(() => import('../pages/enterprise_manager/EnterpriceForm')));
// SECTIONS
const SectionIndex = Loadable(lazy(() => import('../pages/enterprise_manager/Section/Index')));
const SectionCreate = Loadable(lazy(() => import('../pages/enterprise_manager/Section/Create')));
const SectionEdit = Loadable(lazy(() => import('../pages/enterprise_manager/Section/Edit')));
const SectionShow = Loadable(lazy(() => import('../pages/enterprise_manager/Section/Show')));
// GROUPS
const GroupIndex = Loadable(lazy(() => import('../pages/enterprise_manager/Group/Index')));
const GroupCreate = Loadable(lazy(() => import('../pages/enterprise_manager/Group/Create')));
const GroupEdit = Loadable(lazy(() => import('../pages/enterprise_manager/Group/Edit')));
const GroupShow = Loadable(lazy(() => import('../pages/enterprise_manager/Group/Show')));
// ----- MANAGER ------
const ManagerHome = Loadable(lazy(() => import('../pages/enterprise_manager/Home')));
// AREAS
const AreaIndex = Loadable(lazy(() => import('../pages/enterprise_manager/Area/Index')));
const AreaCreate = Loadable(lazy(() => import('../pages/enterprise_manager/Area/Create')));
const AreaEdit = Loadable(lazy(() => import('../pages/enterprise_manager/Area/Edit')));
const AreaShow = Loadable(lazy(() => import('../pages/enterprise_manager/Area/Show')));
// USERMANAGER
const UserManagerIndex = Loadable(lazy(() => import('../pages/enterprise_manager/UserManager/Index')));
const UserManagerCreate = Loadable(lazy(() => import('../pages/enterprise_manager/UserManager/Create')));
const UserManagerEdit = Loadable(lazy(() => import('../pages/enterprise_manager/UserManager/Edit')));
const UserManagerShow = Loadable(lazy(() => import('../pages/enterprise_manager/UserManager/Show')));
// USERS
const UserIndex = Loadable(lazy(() => import('../pages/enterprise_manager/User/Index')));
const UserCreate = Loadable(lazy(() => import('../pages/enterprise_manager/User/Create')));
const UserEdit = Loadable(lazy(() => import('../pages/enterprise_manager/User/Edit')));
const UserShow = Loadable(lazy(() => import('../pages/enterprise_manager/User/Show')));
const OwnInvitations = Loadable(lazy(() => import('../pages/common_user/own_invitations')));
const Invitation = Loadable(lazy(() => import('../pages/common_user/invitation')));
// ---- TEACHER ROUTES -----
const TeacherHome = Loadable(lazy(() => import('../pages/teacher/Home')));
const TeacherStart = Loadable(lazy(() => import('../pages/teacher/Start')));
const TeacherSectionsExplorer = Loadable(lazy(() => import('../pages/teacher/SectionsExplorer')));
const TeacherFacturationData = Loadable(lazy(() => import('../pages/teacher/FacturationData')));
// Questions
const QuestionIndex = Loadable(lazy(() => import('../pages/teacher/Question/Index')));
const QuestionCreate = Loadable(lazy(() => import('../pages/teacher/Question/Create')));
const QuestionEdit = Loadable(lazy(() => import('../pages/teacher/Question/Edit')));
const QuestionSetResponse = Loadable(lazy(() => import('../pages/teacher/Question/Step2SetResponseForm')));
const QuestionShow = Loadable(lazy(() => import('../pages/teacher/Question/Show')));
// Quizzes
const QuizIndex = Loadable(lazy(() => import('../pages/teacher/Quiz/Index')));
const QuizCreate = Loadable(lazy(() => import('../pages/teacher/Quiz/Create')));
const QuizEdit = Loadable(lazy(() => import('../pages/teacher/Quiz/Edit')));
const QuizShow = Loadable(lazy(() => import('../pages/teacher/Quiz/Show')));
// VideoContent
const VideoContentIndex = Loadable(lazy(() => import('../pages/teacher/VideoContent/Index')));
const VideoContentCreate = Loadable(lazy(() => import('../pages/teacher/VideoContent/Create')));
const VideoContentEdit = Loadable(lazy(() => import('../pages/teacher/VideoContent/Edit')));
const VideoContentShow = Loadable(lazy(() => import('../pages/teacher/VideoContent/Show')));

// ---- STUDENT ROUTES -----
const QuizPreview = Loadable(lazy(() => import('../pages/student/QuizPreview')));
const Quiz = Loadable(lazy(() => import('../pages/student/Quiz')));
const QuizSection = Loadable(lazy(() => import('../pages/student/QuizSection')));
const StudentHome = Loadable(lazy(() => import('../pages/student/Home')));
const StudentArea = Loadable(lazy(() => import('../pages/student/Area')));
// ---- STUDENT ROUTES NO LOGIN-----
const Group = Loadable(lazy(() => import('../pages/student/Group')));
