import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/**
 This JavaScript file `group.js` is part of a Redux architecture and is responsible for managing the state of a 'group' entity. It uses Redux Toolkit's createSlice function to generate actions and reducers. The initial state of a group includes properties like `isLoading`, `error`, `groups`, `group`, `sortBy`, and `filters`. The file exports several asynchronous action creators that trigger API calls for different operations such as getting all groups, getting a single group by id, creating a group, updating a group, and deleting a group. These action creators dispatch different actions to update the state based on the result of the API calls. The slice reducer exported from this file handles all the actions generated by the slice and changes the state accordingly.
*/

const BASE_URL = '/api/groups';

const initialState = {
  isLoading: false,
  error: null,
  groups: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  group: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET groups
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET Group
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.group = action.payload;
    },

    // Create Group
    createGroupSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // edit Group
    editGroupSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // delete Group
    deleteGroup(state, id) {
      state.isLoading = false;
      state.groups = state.groups.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setGroups(state, action) {
      state.groups = action.payload;
    },

    //  SORT & FILTER Group
    sortByGroup(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getGroups(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getGroup(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getGroup response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createGroup(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.group));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateGroup(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.group));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteGroupSuccess(id));
      // Actualiza el estado con las secciones después de eliminar una
      // utilizando la acción 'setGroups'
      dispatch(slice.actions.deleteGroups(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
