import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';

import section from './slices/section';
import questionReducer from './slices/question';
import videoContentReducer from './slices/videoContent';
import groupReducer from './slices/group';
import quizReducer from './slices/quiz';
import enterprise from './slices/enterprise';
import enterpriseAreaReducer from './slices/enterprise_area';
import enterpriseManagerReducer from './slices/enterprise_manager';
import enterpriseUserReducer from './slices/enterprise_user';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import employeeReducer from './slices/employee';
import studentReducer from './slices/student';
import userReducer from './slices/user';

/** 
The `rootReducer.js` file is part of a Redux store configuration. It combines all the reducers from different slices of the state into a single root reducer. It also uses the `redux-persist` library to persist some parts of the state across sessions. The slices of the state that are persisted include quiz, section, question, videoContent, group, student, enterprise, enterprise_area, enterprise_manager, enterprise_user, and user. The persistence configuration for each slice includes the storage mechanism, a key, a key prefix, and a whitelist of state keys to persist.
*/

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const sectionPersistConfig = {
  key: 'sections',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const questionPersistConfig = {
  key: 'questions',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const quizPersistConfig = {
  key: 'quizzes',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const videoContentPersistConfig = {
  key: 'videoContents',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const groupPersistConfig = {
  key: 'groups',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const studentPersistConfig = {
  key: 'student',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const enterprisePersistConfig = {
  key: 'enterprise',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const enterpriseAreaPersistConfig = {
  key: 'enterprise_area',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const enterpriseManagerPersistConfig = {
  key: 'enterprise_manager',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const enterpriseUserPersistConfig = {
  key: 'enterprise_user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,

  quiz: persistReducer(quizPersistConfig, quizReducer),
  section: persistReducer(sectionPersistConfig, section),
  question: persistReducer(questionPersistConfig, questionReducer),
  videoContent: persistReducer(videoContentPersistConfig, videoContentReducer),
  group: persistReducer(groupPersistConfig, groupReducer),
  student: persistReducer(studentPersistConfig, studentReducer),
  enterprise: persistReducer(enterprisePersistConfig, enterprise),
  enterprise_area: persistReducer(enterpriseAreaPersistConfig, enterpriseAreaReducer),
  enterprise_manager: persistReducer(enterpriseManagerPersistConfig, enterpriseManagerReducer),
  enterprise_user: persistReducer(enterpriseUserPersistConfig, enterpriseUserReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

export { rootPersistConfig, rootReducer };
