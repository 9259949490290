import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import useUserContext from '../hooks/useUserContext';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userEmail: "juanito1@gmail.com",
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SET_EMAIL: (state, action) => {
    const { email } = action.payload;
    return {
      ...state,
      userEmail: email,
    };
  }
};
/**
  This file defines the JWT (JSON Web Token) authentication context for the application. 
  It uses useReducer to handle authentication state and provides an AuthContext that can be accessed by other components to manipulate or access the authentication state.
  The handlers define how the state updates in response to actions like INITIALIZE, LOGIN, LOGOUT, REGISTER, and SET_EMAIL. 
  The AuthProvider wraps the application and supplies the authentication context to its children.
*/

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  google_login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setEmail: () => Promise.resolve(),
});
// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { SetCurrentEnterprise, currentEnterprise } = useUserContext();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`/api/account/account?enterprise_token=${currentEnterprise?.token}`);
          const { user } = response.data;
          console.log("AUTH")

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`/api/account/login`, {
      email,
      password,
    });
    const { accessToken, user } = response.data;
    SetCurrentEnterprise(user?.current_enterprise)
    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const google_login = async (access_token, role_interest, is_teacher) => {
    const response = await axios.post(`/api/account/google_login`, {
      access_token,
      role_interest,
      is_teacher
    });
    const { accessToken, user } = response.data;
    SetCurrentEnterprise(user?.current_enterprise)

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, name, last_name, role_interest, is_teacher) => {
    const response = await axios.post(`/api/account/register`, {
      email,
      password,
      name,
      last_name,
      role_interest,
      is_teacher
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const setEmail = async (email) => {
    dispatch({
      type: 'SET_EMAIL',
      payload: {
        email,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        google_login,
        logout,
        register,
        setEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
