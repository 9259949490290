import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
/** 
The `student.js` file is a Redux slice that manages the state related to student data in the application. It includes actions to start loading, handle errors, and retrieve content, search sections, search groups, quiz instances, group details, and enterprise user areas. The state includes flags for loading and error status, as well as properties for content, group, quiz, quiz instance, groups, sections, current courses, and enterprise areas. This file also exports several async functions that dispatch the actions to perform HTTP requests to various API endpoints using axios and update the state accordingly. These functions include getting section content, video content, sections, groups, generating quizzes, setting a response, finishing a quiz, getting a quiz instance, a group, and enterprise
*/

const BASE_CONTENT = '/api/student_content';
const BASE_QUIZ = '/api/student_quiz';
const BASE_SEARCH = '/api/student_search';
const BASE_GROUP = '/api/student_group';
const BASE_STUDENT = '/api/student';

const initialState = {
  isLoading: false,
  content: null,
  group: null,
  quiz: null,
  quizInstance: null,
  groups: [],
  sections: [],
  error: "",
  // enterprise currentData
  currentCourses: [],
  enterpriseAreas: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET content
    getContentSuccess(state, action) {
      state.isLoading = false;
      state.content = action.payload;
    },

    // GET search_sections
    getSearchSectionSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    // GET search_groups
    getSearchGroupSuccess(state, action) {
      state.isLoading = false;
      state.groups = action.payload;
    },

    // GET quiz_instance
    getQuizInstanceSuccess(state, action) {
      state.isLoading = false;
      state.quizInstance = action.payload;
    },

    // SET response
    setResponseSuccess(state, action) {
      state.isLoading = false;
    },

    // GET group
    getGroupSuccess(state, action) {
      state.isLoading = false;
      state.group = action.payload;
    },

    // GET enterprise_areas
    getEnterpriseUserAreasSuccess(state, action) {
      state.isLoading = false;
      state.enterpriseAreas = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getContentSuccess, getSearchSectionSuccess, getQuizInstanceSuccess, getGroupSuccess, getSearchGroupSuccess } = slice.actions;

// Functions
export function getSectionContent(id) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`${BASE_CONTENT}/section_content/${id}`);
      console.log('getSection response', response);
      dispatch(getContentSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

export function getVideoContent(video_id) {
  return async () => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`${BASE_CONTENT}/get_video_content/${video_id}`);
      console.log('getSection response', response);
      dispatch(getContentSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

export function getSections(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_SEARCH}/search`, {params: filter});
      console.log("getSections response", response)
      dispatch(slice.actions.getSearchSectionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGroups(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_SEARCH}/search_groups`, {params: filter});
      console.log("getCourses response", response)
      dispatch(slice.actions.getSearchGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function generateQuiz(id, retry, group_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_QUIZ}/generate_quiz/${id}?retry=${retry}&group_id=${group_id}`);
      console.log("geberateQuiz response", response)
      dispatch(slice.actions.getQuizInstanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function generateSectionQuiz(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_QUIZ}/generate_section_quiz/${id}`);
      console.log("geberateSectionQuiz response", response)
      dispatch(slice.actions.getQuizInstanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setResponse(answerIndex, questionIndex, quizInstanceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${BASE_QUIZ}/set_answer?quiz_instance_id=${quizInstanceId}&&question_index=${questionIndex}&&answer_index=${answerIndex}`);
      dispatch(slice.actions.setResponseSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function finishQuiz(quizInstanceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${BASE_QUIZ}/finish_quiz?quiz_instance_id=${quizInstanceId}`);
      dispatch(slice.actions.getQuizInstanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error; 
    }
  };
}

export function getQuizInstance(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_QUIZ}/get_quiz_instance/${id}`);
      console.log("getQuizInstance response", response)
      dispatch(slice.actions.getQuizInstanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGroup(token){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_GROUP}/show/${token}`);
      console.log("getGroup response", response)
      dispatch(slice.actions.getGroupSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEnterpriseUserAreas() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_STUDENT}/get_areas`);
      console.log("getAreas response", response)
      dispatch(slice.actions.getEnterpriseUserAreasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
