import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/** 
The `section.js` file is part of the Redux setup in a JavaScript application. It defines a Redux slice for managing 'section' related state in the application. The state includes properties such as `isLoading`, `error`, `sections`, `section`, `sortBy`, and `filters`. 

The slice contains several actions and reducers to manage these state properties, including starting and stopping loading, handling errors, getting all sections, getting a single section, creating, editing and deleting a section, setting sections, and sorting and filtering sections. 

Additionally, the file exports several async action creators that make HTTP requests to a server to get, create, update, and delete sections. These action creators dispatch the actions defined in the slice to update the state based
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/sections';

const initialState = {
  isLoading: false,
  error: null,
  sections: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  section: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET sections
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    // GET Section
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.section = action.payload;
    },

    // Create Section
    createSectionSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // edit Section
    editSectionSuccess(state, action) {
      state.isLoading = false;
      state.upload_link = action.payload;
    },

    // delete Section
    deleteSection(state, id) {
      state.isLoading = false;
      state.sections = state.sections.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setSections(state, action) {
      state.sections = action.payload;
    },

    //  SORT & FILTER Section
    sortBySection(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getSections(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSection(id) {
  console.log("getSection")
  console.log(id)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getSection response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSection(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.section));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSection(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.section));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSection(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteSectionSuccess(id));
      // Actualiza el estado con las secciones después de eliminar una
      // utilizando la acción 'setSections'
      dispatch(slice.actions.deleteSections(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
