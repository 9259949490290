import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/** 
The `src/redux/slices/enterprise_manager.js` file is a Redux slice for managing the state of enterprise managers in the application. It uses Redux Toolkit's `createSlice` to generate action creators and action types. The slice contains an initial state and several reducer functions to handle different actions such as loading, error handling, getting, creating, editing, and deleting enterprise managers. It also provides functions for sorting and filtering. The file also exports async thunk actions to fetch, create, update, and delete enterprise managers from a server using axios for HTTP requests. The base URL for the server API is '/api/enterprise_managers'.
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/enterprise_managers';

const initialState = {
  isLoading: false,
  error: null,
  enterprise_managers: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  enterprise_manager: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'enterprise_managers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET enterprise_managers
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_managers = action.payload;
    },

    // GET EnterpriseManager
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_manager = action.payload;
    },

    // Create EnterpriseManager
    createEnterpriseManagerSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_manager = action.payload;
    },

    // edit EnterpriseManager
    editEnterpriseManagerSuccess(state, action) {
      state.isLoading = false;
      state.enterprise_manager = action.payload;
    },

    // delete EnterpriseManager
    deleteEnterpriseManager(state, id) {
      state.isLoading = false;
      state.enterprise_managers = state.enterprise_managers.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setEnterpriseManagers(state, action) {
      state.enterprise_managers = action.payload;
    },

    //  SORT & FILTER EnterpriseManager
    sortByEnterpriseManager(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getEnterpriseManagers(filter) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getEnterpriseManager(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getEnterpriseManager response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createEnterpriseManager(data) {
  console.log("DATA", data);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(BASE_URL, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_manager));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEnterpriseManager(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data.enterprise_manager));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEnterpriseManager(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteEnterpriseManagerSuccess(id));
      dispatch(slice.actions.deleteEnterpriseManagers(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

