import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const initialState = {
  group_context: null,
  quiz_context: null,
  search_context: null,
  currentEnterprise: null
};

const UserContext = createContext(initialState);

// ----------------------------------------------------------------------

UserDrawerProvider.propTypes = {
  children: PropTypes.node,
};
/**
 User Context Provider
 Manage current Selected enterprise of user
 Manage currentCourse that see the user
*/
function UserDrawerProvider({ children }) {
  
  const [context, setContext] = useLocalStorage('user_context', {
    groupContext: null,
    quizContext: null,
    searchSectionContext: null,
    searchGroupContext: null,
    currentEnterprise: null
  });

  const setGroup = (group) => {
    setContext({
        ...context,
        groupContext: group,
        quizContext: null,
        searchSectionContext: null,
        searchGroupContext: null
    });
  };

  const SetCurrentEnterprise = (enterprise) => {
    setContext({
        ...context,
        currentEnterprise: enterprise
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...context,
        setGroup,
        SetCurrentEnterprise
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserDrawerProvider, UserContext };
