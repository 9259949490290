import PropTypes from 'prop-types';
/** 
The file `src/guards/GuestGuard.js` is a React component that acts as a guard for guest users. It uses a custom hook `useAuth` to check if the user is authenticated or not. If the user is authenticated, they are redirected to the dashboard. If the user's authentication status has not been determined yet (isInitialized is false), a loading screen is displayed. If the user is not authenticated, it simply returns the child components. The child components are expected to be a React node, as defined by the PropTypes.
*/
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
