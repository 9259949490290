import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fil } from 'date-fns/locale';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
/** 
This is a Redux slice file for managing questions in an application. It contains actions and reducers for various operations related to questions such as starting the loading process, handling errors, getting all questions, getting a single question, creating a question, editing a question, deleting a question, setting questions, and sorting & filtering questions. The file also includes async functions that dispatch these actions to perform HTTP requests to a REST API located at '/api/questions'. The actions update the state of the application based on the results of these requests. The state for this slice includes properties for loading status, errors, a list of questions, the current question, sorting, and filters.
*/

// ----------------------------------------------------------------------

const BASE_URL = '/api/questions';

const initialState = {
  isLoading: false,
  error: null,
  questions: [{id:1, title: 'uploadLink 1', objective: 'description 1', desctiption: 'url 1'}],
  question: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
};

const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET questions
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    // GET Question
    getSingleSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // Create Question
    createQuestionSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // edit Question
    editQuestionSuccess(state, action) {
      state.isLoading = false;
      state.question = action.payload;
    },

    // delete Question
    deleteQuestion(state, id) {
      state.isLoading = false;
      state.questions = state.questions.filter((s) => s.id !== id);
    },

    // SET SECTIONS
    setQuestions(state, action) {
      state.questions = action.payload;
    },

    //  SORT & FILTER Question
    sortByQuestion(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getQuestions(filter) {
  console.log("Filter")
  console.log(filter)
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(BASE_URL, {params: filter});
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getQuestion(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      console.log("getQuestion response", response)
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createQuestion(data) {
  try {
    const response = await axios.post(BASE_URL, data);
    return response.data?.id;
  } catch (error) {
    console.error("Error al crear la pregunta:", error);
    throw error;
  }
}

export function updateQuestion(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, data);
      dispatch(slice.actions.getSingleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteQuestion(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      dispatch(slice.actions.deleteQuestionSuccess(id));
      // Actualiza el estado con las secciones después de eliminar una
      // utilizando la acción 'setQuestions'
      dispatch(slice.actions.deleteQuestions(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
