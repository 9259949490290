import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  error: null,
  invitation: null,
  invitations: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET uploaded_files
    getIndexSuccess(state, action) {
      state.isLoading = false;
      state.invitations = action.payload;
    },

    gestInvitationSuccess(state, action) {
      state.isLoading = false;
      state.invitation = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    },

    saveProfileSuccess(state, action) {
      state.isLoading = false;
    },
}});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getInvitations() {
  console.log("dispatching getinvitations")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/invitations`);
      console.log("Response")
      dispatch(slice.actions.getIndexSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function acceptInvitation(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/accept_invitation/${token}`);
      dispatch(slice.actions.gestInvitationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getProfile() {
  console.log("dispatching getprofile")
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/get_profile`);
      console.log("Response", response);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveProfile(data) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(`/api/users/save_profile`, data);
      console.log("Response", response);
      dispatch(slice.actions.saveProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
